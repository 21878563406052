import Link from "next/link";
import { Layout } from "../components/layout";
import { Brand } from "../components/snippets";

export default function Custom404() {
  return (
    <Layout showLoading={false} showModal={false}>
      <div className='h-[768px] xl:h-[960px] w-screen'>
        <div className='container w-full h-full mx-auto flex flex-col items-center justify-center'>
          <Brand />
          <h1 className='text-center text-4xl md:text-4xl lg:text-7xl font-semibold  mt-16 tracking-wide'>
            Sorry, this page does not exist &#128577;
          </h1>
          <Link href='/' passHref>
            <p className='cursor-pointer px-6 py-3 bg-brand-orange shadow-sm border border-brand-peach-medium hover:-translate-y-2 duration-500  mt-12 text-3xl '>
              Back to Home
            </p>
          </Link>
        </div>
      </div>
    </Layout>
  );
}
